exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-6-reasons-info-js": () => import("./../../../src/pages/6ReasonsInfo.js" /* webpackChunkName: "component---src-pages-6-reasons-info-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contactForm.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-cookie-jsx": () => import("./../../../src/pages/cookie.jsx" /* webpackChunkName: "component---src-pages-cookie-jsx" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-form-error-jsx": () => import("./../../../src/pages/form-error.jsx" /* webpackChunkName: "component---src-pages-form-error-jsx" */),
  "component---src-pages-form-success-jsx": () => import("./../../../src/pages/form-success.jsx" /* webpackChunkName: "component---src-pages-form-success-jsx" */),
  "component---src-pages-gallery-1-js": () => import("./../../../src/pages/gallery1.js" /* webpackChunkName: "component---src-pages-gallery-1-js" */),
  "component---src-pages-gallery-2-js": () => import("./../../../src/pages/gallery2.js" /* webpackChunkName: "component---src-pages-gallery-2-js" */),
  "component---src-pages-gallery-3-js": () => import("./../../../src/pages/gallery3.js" /* webpackChunkName: "component---src-pages-gallery-3-js" */),
  "component---src-pages-gallery-4-js": () => import("./../../../src/pages/gallery4.js" /* webpackChunkName: "component---src-pages-gallery-4-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-orig-js": () => import("./../../../src/pages/index-ORIG.js" /* webpackChunkName: "component---src-pages-index-orig-js" */),
  "component---src-pages-kultura-js": () => import("./../../../src/pages/kultura.js" /* webpackChunkName: "component---src-pages-kultura-js" */),
  "component---src-pages-leto-js": () => import("./../../../src/pages/leto.js" /* webpackChunkName: "component---src-pages-leto-js" */),
  "component---src-pages-lightbox-js": () => import("./../../../src/pages/lightbox.js" /* webpackChunkName: "component---src-pages-lightbox-js" */),
  "component---src-pages-non-stretched-image-js": () => import("./../../../src/pages/nonStretchedImage.js" /* webpackChunkName: "component---src-pages-non-stretched-image-js" */),
  "component---src-pages-obchodne-podmienky-js": () => import("./../../../src/pages/obchodne_podmienky.js" /* webpackChunkName: "component---src-pages-obchodne-podmienky-js" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-relax-js": () => import("./../../../src/pages/relax.js" /* webpackChunkName: "component---src-pages-relax-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-ubytovanie-js": () => import("./../../../src/pages/ubytovanie.js" /* webpackChunkName: "component---src-pages-ubytovanie-js" */),
  "component---src-pages-use-gallery-js": () => import("./../../../src/pages/useGallery.js" /* webpackChunkName: "component---src-pages-use-gallery-js" */),
  "component---src-pages-zima-js": () => import("./../../../src/pages/zima.js" /* webpackChunkName: "component---src-pages-zima-js" */)
}

